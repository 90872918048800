function forceFileDownload(res, title) {
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: res.data.type })
  );
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  if (title) {
    link.download = title;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function reviewScoreColor(score) {
  switch (score) {
    case 0:
      return "error";
    case 1:
      return "info";
    case 3:
      return "success";
  }
}

function reviewScoreDescription(score) {
  switch (score) {
    case 0:
      return "Not Recommended";
    case 1:
      return "Recommended";
    case 3:
      return "Strongly Recommended";
  }
}

export default {
  forceFileDownload,
  reviewScoreColor,
  reviewScoreDescription,
};
